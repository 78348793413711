// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { ActionTree } from 'vuex';
import axios, { AxiosRequestConfig } from 'axios';
import { WorkspaceState, types } from './types';
import { RootState } from '../types';

interface WorkspaceEditData {
  workspaceId: string;
  data: {
    host?: string;
    name?: string;
    brand_image?: string;
    profile_image?: string;
    ringtone?: string;
    number_of_days_to_delete_item_permanently?: number;
    is_person_tag_required?: boolean;
  };
}

export const actions: ActionTree<WorkspaceState, RootState> = {
  fetchWorkspace({ commit, rootGetters }, workspaceId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(types.GET_WORKSPACE_REQUEST);

      const requestOptions: AxiosRequestConfig = {
        method: 'get',
        baseURL: process.env.VUE_APP_DECONVE_AUTH_URL,
        url: `/workspaces/${workspaceId}/`,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      axios(requestOptions)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  getWorkspace({ commit, rootGetters }): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(types.GET_WORKSPACE_REQUEST);

      const requestOptions: AxiosRequestConfig = {
        method: 'get',
        baseURL: process.env.VUE_APP_DECONVE_AUTH_URL,
        url: '/workspaces/infos/',
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      axios(requestOptions).then((response) => {
        commit(types.GET_WORKSPACE_SUCCESS, response.data);
        resolve();
      }).catch((error) => {
        commit(types.GET_WORKSPACE_FAILURE, error);
        reject();
      });
    });
  },
  getWorkspaceSettings({ dispatch }): Promise<void> {
    return new Promise((resolve, reject) => {
      const promises = [dispatch('getWorkspace'), dispatch('tags/fetchTags', null, { root: true })];

      Promise.all(promises).then(() => resolve()).catch(() => reject());
    });
  },
  getWorkspaceUsers({ commit, rootGetters }): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(types.GET_WORKSPACE_USERS_REQUEST);

      const requestOptions: AxiosRequestConfig = {
        method: 'get',
        baseURL: process.env.VUE_APP_DECONVE_AUTH_URL,
        url: '/users/',
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      axios(requestOptions).then((response) => {
        commit(types.GET_WORKSPACE_USERS_SUCCESS, response.data);
        resolve();
      }).catch((error) => {
        commit(types.GET_WORKSPACE_USERS_FAILURE, error);
        reject();
      });
    });
  },
  getWorkspaceUserTags({ commit, rootGetters }, id) {
    commit(types.GET_WORKSPACE_USER_TAGS_REQUEST);

    const requestOptions: AxiosRequestConfig = {
      method: 'get',
      baseURL: process.env.VUE_APP_DECONVE_AUTH_URL,
      url: `/users/${id}/tags/`,
      headers: {
        Authorization: rootGetters.authorizationToken,
      },
    };

    axios(requestOptions).then((response) => {
      commit(types.GET_WORKSPACE_USER_TAGS_SUCCESS, { workspaceUserId: id, tags: response.data });
    }).catch((error) => {
      commit(types.GET_WORKSPACE_USER_TAGS_FAILURE, error);
    });
  },
  getUserWorkspaces({ commit, rootGetters }) {
    commit(types.GET_USER_WORKSPACES_REQUEST);

    const requestOptions: AxiosRequestConfig = {
      method: 'get',
      baseURL: process.env.VUE_APP_DECONVE_AUTH_URL,
      url: '/workspaces/',
      headers: {
        Authorization: rootGetters.authorizationToken,
      },
    };

    axios(requestOptions).then((response) => {
      commit(types.GET_USER_WORKSPACES_SUCCESS, response.data);
    }).catch((error) => {
      commit(types.GET_USER_WORKSPACES_FAILURE, error);
    });
  },
  updateWorkspaceUser({ commit, rootGetters }, {
    id, scopes, role, eventTypes,
  }) {
    commit(types.UPDATE_WORKSPACE_USER_REQUEST);

    const requestOptions: AxiosRequestConfig = {
      method: 'put',
      baseURL: process.env.VUE_APP_DECONVE_AUTH_URL,
      url: `/users/${id}/`,
      headers: {
        Authorization: rootGetters.authorizationToken,
      },
      data: {
        scopes,
        role,
        // eslint-disable-next-line @typescript-eslint/camelcase
        event_types: eventTypes,
      },
    };

    axios(requestOptions).then((response) => {
      commit(types.UPDATE_WORKSPACE_USER_SUCCESS, response.data);
    }).catch((error) => {
      commit(types.UPDATE_WORKSPACE_USER_FAILURE, error);
    });
  },
  deleteWorkspaceUser({ commit, rootGetters }, id): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(types.DELETE_WORKSPACE_USER_REQUEST);

      const requestOptions: AxiosRequestConfig = {
        method: 'delete',
        baseURL: process.env.VUE_APP_DECONVE_AUTH_URL,
        url: `/users/${id}/`,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      axios(requestOptions).then(() => {
        commit(types.DELETE_WORKSPACE_USER_SUCCESS, id);
        resolve();
      }).catch((error) => {
        commit(types.DELETE_WORKSPACE_USER_FAILURE, error);
        reject();
      });
    });
  },
  getInvites({ commit, rootGetters }) {
    commit(types.GET_INVITES_REQUEST);

    const requestOptions: AxiosRequestConfig = {
      method: 'get',
      baseURL: process.env.VUE_APP_DECONVE_AUTH_URL,
      url: '/workspaces/invites/',
      headers: {
        Authorization: rootGetters.authorizationToken,
      },
    };

    axios(requestOptions).then((response) => {
      commit(types.GET_INVITES_SUCCESS, response.data);
    }).catch((error) => {
      commit(types.GET_INVITES_FAILURE, error);
    });
  },
  deleteInvite({ commit, rootGetters, dispatch }, inviteId) {
    commit(types.DELETE_INVITE_REQUEST);

    const requestOptions: AxiosRequestConfig = {
      method: 'delete',
      baseURL: process.env.VUE_APP_DECONVE_AUTH_URL,
      url: `/workspaces/invites/${inviteId}/`,
      headers: {
        Authorization: rootGetters.authorizationToken,
      },
    };

    axios(requestOptions).then(() => {
      commit(types.DELETE_INVITE_SUCCESS);
      dispatch('getInvites');
    }).catch((error) => {
      commit(types.DELETE_INVITE_FAILURE, error);
    });
  },
  inviteUserToWorkspace({ commit, rootGetters }, {
    email, scopes, role, tagIds, eventTypes,
  }): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(types.INVITE_USER_REQUEST);

      const requestOptions: AxiosRequestConfig = {
        method: 'post',
        baseURL: process.env.VUE_APP_DECONVE_AUTH_URL,
        url: '/workspaces/invites/',
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
        data: {
          scopes,
          role,
          // eslint-disable-next-line @typescript-eslint/camelcase
          user_email: email,
          // eslint-disable-next-line @typescript-eslint/camelcase
          redirect_url: process.env.VUE_APP_DECONVE_ACCOUNT_APP_URL,
          // eslint-disable-next-line @typescript-eslint/camelcase
          tag_ids: tagIds,
          // eslint-disable-next-line @typescript-eslint/camelcase
          event_types: eventTypes,
        },
      };

      axios(requestOptions).then((response) => {
        commit(types.INVITE_USER_SUCCESS, response.data);
        resolve();
      }).catch((error) => {
        commit(types.INVITE_USER_FAILURE, error);
        reject();
      });
    });
  },
  editWorkspace({ commit, rootGetters }, workspace: WorkspaceEditData) {
    return new Promise((resolve, reject) => {
      commit(types.GET_WORKSPACE_REQUEST);

      const requestOptions: AxiosRequestConfig = {
        method: 'patch',
        baseURL: process.env.VUE_APP_DECONVE_AUTH_URL,
        url: `/workspaces/${workspace.workspaceId}/`,
        data: workspace.data,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      axios(requestOptions).then((response) => {
        const { data: workspaceData } = response;

        commit(types.GET_WORKSPACE_SUCCESS, workspaceData);
        resolve(workspaceData);
      }).catch((error) => {
        commit(types.GET_WORKSPACE_FAILURE, error);
        reject(error);
      });
    });
  },
  getWorkspaceInfoById({ rootGetters }, workspaceId: string) {
    return new Promise((resolve, reject) => {
      const requestOptions: AxiosRequestConfig = {
        method: 'get',
        baseURL: process.env.VUE_APP_DECONVE_AUTH_URL,
        url: `/workspaces/${workspaceId}/`,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      axios(requestOptions).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
  },
};

export default actions;
