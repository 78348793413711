// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { defineAbility, Ability, AbilityBuilder } from '@casl/ability';
import store from '@/store';
import Vue from 'vue';

export default defineAbility((can, cannot) => {
  // CRUD abilities are default for all subjects/endpoints
  cannot('create', 'all');
  cannot('read', 'all');
  cannot('update', 'all');
  cannot('delete', 'all');

  // User can use an app
  cannot('use', 'all');
});

export function updateAbility(): void {
  const { can, rules } = new AbilityBuilder(Ability);
  const { userScopes, userRole } = store.getters;
  const workspaceInfo = store.getters['workspace/myWorkspace'];
  const { apps } = workspaceInfo;

  apps.forEach((app: string) => can('use', app));

  if (userRole === 'admin') {
    can('create', 'all');
    can('read', 'all');
    can('update', 'all');
    can('delete', 'all');
  } else {
    userScopes.forEach((scope: string) => {
      const [action, subject] = scope.split(':', 2);

      can(action, subject);
    });
  }

  Vue.prototype.$ability.update(rules);
  store.dispatch('updateSocketEventList');
}
