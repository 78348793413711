// Copyright (C) 2020 Deconve Technology. All rights reserved.

import { Route, NavigationGuardNext } from 'vue-router';
import auth from '../services/auth';

export default {
  render: () => null,
  beforeRouteEnter(to: Route, _from: Route, next: NavigationGuardNext<Vue>) {
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    const { query } = to;
    const nextUri = query.next ? query.next : '/';
    const { code, workspace_host: workspaceHost } = query;

    auth.setRefreshToken(code as string);
    auth.setWorkspaceHost(workspaceHost as string);
    next(nextUri as string);
  },
};
