// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { AxiosError } from 'axios';

export const types: { [id: string]: string } = {
  GET_WORKSPACE_REQUEST: 'GET_WORKSPACE_REQUEST',
  GET_WORKSPACE_SUCCESS: 'GET_WORKSPACE_SUCCESS',
  GET_WORKSPACE_FAILURE: 'GET_WORKSPACE_FAILURE',

  GET_WORKSPACE_USERS_REQUEST: 'GET_WORKSPACE_USERS_REQUEST',
  GET_WORKSPACE_USERS_SUCCESS: 'GET_WORKSPACE_USERS_SUCCESS',
  GET_WORKSPACE_USERS_FAILURE: 'GET_WORKSPACE_USERS_FAILURE',

  UPDATE_WORKSPACE_USER_REQUEST: 'UPDATE_WORKSPACE_USER_REQUEST',
  UPDATE_WORKSPACE_USER_SUCCESS: 'UPDATE_WORKSPACE_USER_SUCCESS',
  UPDATE_WORKSPACE_USER_FAILURE: 'UPDATE_WORKSPACE_USER_FAILURE',

  DELETE_WORKSPACE_USER_REQUEST: 'DELETE_WORKSPACE_USER_REQUEST',
  DELETE_WORKSPACE_USER_SUCCESS: 'DELETE_WORKSPACE_USER_SUCCESS',
  DELETE_WORKSPACE_USER_FAILURE: 'DELETE_WORKSPACE_USER_FAILURE',

  GET_WORKSPACE_USER_TAGS_REQUEST: 'GET_WORKSPACE_USER_TAGS_REQUEST',
  GET_WORKSPACE_USER_TAGS_SUCCESS: 'GET_WORKSPACE_USER_TAGS_SUCCESS',
  GET_WORKSPACE_USER_TAGS_FAILURE: 'GET_WORKSPACE_USER_TAGS_FAILURE',

  GET_USER_WORKSPACES_REQUEST: 'GET_USER_WORKSPACES_REQUEST',
  GET_USER_WORKSPACES_SUCCESS: 'GET_USER_WORKSPACES_SUCCESS',
  GET_USER_WORKSPACES_FAILURE: 'GET_USER_WORKSPACES_FAILURE',

  GET_INVITES_REQUEST: 'GET_INVITES_REQUEST',
  GET_INVITES_SUCCESS: 'GET_INVITES_SUCCESS',
  GET_INVITES_FAILURE: 'GET_INVITES_FAILURE',

  DELETE_INVITE_REQUEST: 'DELETE_INVITE_REQUEST',
  DELETE_INVITE_SUCCESS: 'DELETE_INVITE_SUCCESS',
  DELETE_INVITE_FAILURE: 'DELETE_INVITE_FAILURE',

  INVITE_USER_REQUEST: 'INVITE_USER_REQUEST',
  INVITE_USER_SUCCESS: 'INVITE_USER_SUCCESS',
  INVITE_USER_FAILURE: 'INVITE_USER_FAILURE',

  EDIT_WORKSPACE_REQUEST: 'EDIT_WORKSPACE_REQUEST',
  EDIT_WORKSPACE_SUCCESS: 'EDIT_WORKSPACE_SUCCESS',
  EDIT_WORKSPACE_FAILURE: 'EDIT_WORKSPACE_FAILURE',

  RESET_ERROR: 'RESET_ERROR',
};

export interface SharedWorkspace {
  workspace: {
    id: string;
  };
  scopes: string[];
}

export interface Workspace {
  id: string;
  host: string;
  name: string;
  profile_image: string;
  brand_image: string;
  created_at: string;
  ringtone: string;
  shared_with: SharedWorkspace[];
  shared_with_me: SharedWorkspace[];
}

export interface Tag {
  id: string;
  tag: string;
  workspace: string;
  user: string;
  createdAt: string;
}

export interface User {
  id: string;
  user: {
    id: string;
    name: string;
    email: string;
  };
  role: string;
  scopes: string[];
  tags: Tag[];
  created_at: string;
}

export interface Invite {
  email: string;
  sent_by: {
    id: string;
  };
  roles: string;
  scopes: string[];
}

export interface WorkspaceUser {
  role: string;
  scopes: string[];
  workspaceUserId: string;
  workspace: Workspace;
  createdAt: string;
}

export interface WorkspaceState {
  workspace: Workspace;
  workspaceList: WorkspaceUser[];
  workspaceUsers: User[];
  invites: Invite[];
  isLoading: boolean;
  error?: AxiosError;
}
