// Copyright (C) 2020 Deconve Technology. All rights reserved.

import { ActionTree } from 'vuex';
import axios, { AxiosRequestConfig } from 'axios';

import { AuthState, types } from './types';
import { RootState } from '../types';
import { types as socketTypes } from '../socket/types';

// On safari, we get an error related to header authorization using axios.
// A related bug is defined on https://github.com/axios/axios/issues/891
// The solution is based on https://github.com/axios/axios/issues/891#issuecomment-572050041

const INTERLVAL_TIMEOUT_MS = 15 * 60 * 1000; // Update every 15 minutes

export const actions: ActionTree<AuthState, RootState> = {
  stopTokenUpdate({ commit, state }): void {
    if (state.tokenUpdateTimerId) {
      clearInterval(state.tokenUpdateTimerId);
      commit(types.SET_UPDATE_TOKEN_TIMER_ID, undefined);
    }
  },
  firebaseCloudMessagingTokenUpdateHelper({ state }, requestMethod): Promise<void> {
    return new Promise((resolve, reject) => {
      const fcmToken = localStorage.getItem('fcmToken');
      const { authorizationToken } = state;

      if (fcmToken) {
        const fcmTokenRequestOptions: AxiosRequestConfig = {
          method: requestMethod,
          baseURL: process.env.VUE_APP_DECONVE_AUTH_URL,
          url: '/users/devices/',
          data: {
            token: fcmToken,
          },
          headers: {
            Authorization: authorizationToken as string,
          },
        };

        axios(fcmTokenRequestOptions)
          .then(() => resolve())
          .catch(() => reject());
      }
    });
  },
  firebaseCloudMessagingTokenUpdate({ dispatch }): void {
    dispatch('firebaseCloudMessagingTokenUpdateHelper', 'post');
  },
  firebaseCloudMessagingTokenDelete({ dispatch }): void {
    dispatch('firebaseCloudMessagingTokenUpdateHelper', 'delete');
  },
  startTokenUpdate({ commit, dispatch }): void {
    dispatch('stopTokenUpdate');

    const timerId = window.setInterval(() => dispatch('userLogin'), INTERLVAL_TIMEOUT_MS);

    commit(types.SET_UPDATE_TOKEN_TIMER_ID, timerId);
  },
  userLogout({ commit, dispatch, state }): void {
    if (navigator.userAgent.includes('DeconveMobileApp')) {
      dispatch('firebaseCloudMessagingTokenDelete');
    }

    dispatch('stopTokenUpdate');
    dispatch(socketTypes.SOCKET_CLOSE_CONNECTION);
    const { authorizationToken } = state;

    commit(types.AUTH_LOGOUT);

    const token = localStorage.getItem('token');

    if (token) {
      localStorage.removeItem('token');

      const requestOptions: AxiosRequestConfig = {
        method: 'post',
        baseURL: process.env.VUE_APP_DECONVE_AUTH_URL,
        url: '/revoke/',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authorizationToken as string,
        },
        data: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          refresh_token: token,
        },
      };

      axios(requestOptions).then(() => {
        window.location.reload();
      }).catch(() => {
        window.location.reload();
      });
    } else {
      window.location.reload();
    }
  },
  userLoggedIn({
    commit, dispatch, state, getters,
  }): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(types.GET_USER_INFO_REQUEST);

      if (navigator.userAgent.includes('DeconveMobileApp')) {
        dispatch('firebaseCloudMessagingTokenUpdate');
      }

      const userInfoRequestOptions: AxiosRequestConfig = {
        method: 'get',
        baseURL: process.env.VUE_APP_DECONVE_AUTH_URL,
        url: '/userinfo/',
        headers: {
          Authorization: state.authorizationToken as string,
        },
      };

      const userInfoRequest = axios(userInfoRequestOptions);

      const introspectRequestOptions: AxiosRequestConfig = {
        method: 'get',
        baseURL: process.env.VUE_APP_DECONVE_AUTH_URL,
        url: '/introspect/',
        headers: {
          Authorization: state.authorizationToken as string,
        },
      };

      const introspectRequest = axios(introspectRequestOptions);

      axios.all([userInfoRequest, introspectRequest]).then((response) => {
        const { data: userInfoData } = response[0];
        const { data: introspectData } = response[1];

        commit(types.GET_USER_INFO_SUCCESS, userInfoData);
        commit(types.GET_USER_INTROSPECT_SUCCESS, introspectData);
        // user is considered logged if we can get the user info
        commit(types.AUTH_LOGIN);

        if (!getters.isTokenUpdateEnabled) {
          dispatch('startTokenUpdate');
        }

        resolve();
      }).catch((error) => {
        commit(types.GET_USER_INFO_FAILURE, error);
        reject();
      });
    });
  },
  userLogin({ commit, dispatch }): Promise<void> {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token');
      const host = localStorage.getItem('host');
      // eslint-disable-next-line @typescript-eslint/camelcase
      const data = { refresh_token: token };

      if (host !== 'undefined' && host !== '') {
        Reflect.set(data, 'workspace_host', host);
      }

      if (token) {
        commit(types.USER_LOGIN_REQUEST);

        const requestOptions: AxiosRequestConfig = {
          method: 'post',
          baseURL: process.env.VUE_APP_DECONVE_AUTH_URL,
          url: '/token/',
          headers: {
            'Content-Type': 'application/json',
          },
          data,
        };

        axios(requestOptions).then((response) => {
          const { data: responseData } = response;
          // The authorization token pattern is 'Bearer token'. Any whitespace in the start or end
          // breaks the authorization token
          const authorizationToken = `${responseData.token_type} ${responseData.access_token}`;

          commit(types.USER_LOGIN_SUCCESS, authorizationToken);

          // to update the socket authotization token
          dispatch(socketTypes.SOCKET_UPDATE_AUTH_TOKEN, authorizationToken);

          // to update the user information, as his scopes, for example
          dispatch('userLoggedIn').then(() => resolve()).catch(() => reject());
        }).catch(() => {
          commit(types.USER_LOGIN_FAILURE);
          reject();
        });
      } else {
        commit(types.USER_LOGIN_FAILURE);
        reject();
      }
    });
  },
  enableDemoMode({ commit }, enable: boolean): void {
    if (enable) {
      localStorage.setItem('demo', '1');
    } else {
      localStorage.removeItem('demo');
    }

    commit(types.ENABLE_DEMO_MODE, enable);
  },
};

export default actions;
