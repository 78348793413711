// Copyright (C) 2020 Deconve Technology. All rights reserved.

import { AxiosError } from 'axios';

export const types: { [id: string]: string } = {
  GET_FACEID_PEOPLE_SUCCESS: 'GET_FACEID_PEOPLE_SUCCESS',
  GET_FACEID_PERSON_FINDER_PEOPLE_SUCCESS: 'GET_FACEID_PERSON_FINDER_PEOPLE_SUCCESS',
  GET_FACEID_PERSON_FINDER_IMAGE_FACES: 'GET_FACEID_PERSON_FINDER_IMAGE_FACES',

  ADD_FACEID_PERSON_FINDER_IMAGE: 'ADD_FACEID_PERSON_FINDER_IMAGE',
  REMOVE_FACEID_PERSON_FINDER_IMAGE: 'REMOVE_FACEID_PERSON_FINDER_IMAGE',
  RESET_FACEID_PERSON_FINDER_IMAGE_FACES: 'RESET_FACEID_PERSON_FINDER_IMAGE_FACES',
  SET_FACEID_PERSON_FINDER_IMAGE_FACE_INDEX: 'SET_FACEID_PERSON_FINDER_IMAGE_FACE_INDEX',
  SET_FACEID_PERSON_FINDER_SCORE_THRESHOLD: 'SET_FACEID_PERSON_FINDER_SCORE_THRESHOLD',
  SET_FACEID_PERSON_FINDER_PAGINATION: 'SET_FACEID_PERSON_FINDER_PAGINATION',
  SET_FACEID_PERSON_FINDER_SELECTED_PEOPLE: 'SET_FACEID_PERSON_FINDER_SELECTED_PEOPLE',

  GET_FACEID_PERSON_TAGS_REQUEST: 'GET_FACEID_PERSON_TAGS_REQUEST',
  GET_FACEID_PERSON_TAGS_SUCCESS: 'GET_FACEID_PERSON_TAGS_SUCCESS',
  GET_FACEID_PERSON_TAGS_FAILURE: 'GET_FACEID_PERSON_TAGS_FAILURE',

  ADD_FACEID_PERSON_TAG_REQUEST: 'ADD_FACEID_PERSON_TAG_REQUEST',
  ADD_FACEID_PERSON_TAG_SUCCESS: 'ADD_FACEID_PERSON_TAG_SUCCESS',
  ADD_FACEID_PERSON_TAG_FAILURE: 'ADD_FACEID_PERSON_TAG_FAILURE',

  ADD_FACEID_PERSON_REQUEST: 'ADD_FACEID_PERSON_REQUEST',
  ADD_FACEID_PERSON_SUCCESS: 'ADD_FACEID_PERSON_SUCCESS',
  ADD_FACEID_PERSON_FAILURE: 'ADD_FACEID_PERSON_FAILURE',

  GET_FACEID_PERSON_REQUEST: 'GET_FACEID_PERSON_REQUEST',
  GET_FACEID_PERSON_SUCCESS: 'GET_FACEID_PERSON_SUCCESS',
  GET_FACEID_PERSON_FAILURE: 'GET_FACEID_PERSON_FAILURE',

  EDIT_FACEID_PERSON_REQUEST: 'EDIT_FACEID_PERSON_REQUEST',
  EDIT_FACEID_PERSON_SUCCESS: 'EDIT_FACEID_PERSON_SUCCESS',
  EDIT_FACEID_PERSON_FAILURE: 'EDIT_FACEID_PERSON_FAILURE',

  DELETE_FACEID_PERSON_TAG_REQUEST: 'DELETE_FACEID_PERSON_TAG_REQUEST',
  DELETE_FACEID_PERSON_TAG_SUCCESS: 'DELETE_FACEID_PERSON_TAG_SUCCESS',
  DELETE_FACEID_PERSON_TAG_FAILURE: 'DELETE_FACEID_PERSON_TAG_FAILURE',

  RESET_FACEID_PERSON_TAG: 'RESET_FACEID_PERSON_TAG',

  DELETE_FACEID_PERSON_REQUEST: 'DELETE_FACEID_PERSON_REQUEST',
  DELETE_FACEID_PERSON_SUCCESS: 'DELETE_FACEID_PERSON_SUCCESS',
  DELETE_FACEID_PERSON_FAILURE: 'DELETE_FACEID_PERSON_FAILURE',
};

export interface Tag {
    id: string;
}

export interface Face {
  detection_score: number;
  image_quality_score: number;
  bounding_box: {
    top_left_x: number;
    top_left_y: number;
    width: number;
    height: number;
  };
  angles: {
    yaw: number;
    pitch: number;
    roll: number;
  };
  landmarks: number[];
}

export interface PersonImage {
  original: string;
  thumbnail: string;
  medium_quality: string;
  original_url: string;
  thumbnail_url: string;
  medium_quality_url: string;
  original_width: number;
  original_height: number;
  faces: Face[];
}

export interface PersonPreview {
  id: string;
  name?: string;
}
export interface History {
  action: string;
  created_at: string;
  created_by: string;
}
export interface PersonReviewSettings {
  video_id: string;
  until: string;
  review_status: string;
}

export interface PersonFiles {
  name: string;
  url: string;
}

export interface PersonVideos {
  name: string;
  url: string;
}

export interface PersonSharing {
  status: string;
  created_at: string;
  updated_at: string;
  about: string;
  tags: Tag[];
}

export interface PersonSharingReview {
  current_review: PersonSharing;
  last_review: PersonSharing;
}

export interface PersonWorkspace {
  id: string;
}

export interface PersonUnit {
  id: string;
}

export interface SuperProfile {
  id: string;
}

export interface Person {
  id?: string;
  workspace?: PersonWorkspace;
  unit?: PersonUnit;
  name?: string;
  created_at?: string;
  updated_at?: string;
  profile_image?: string;
  profile_image_url?: string;
  expire_mode?: string;
  expire_at?: string;
  deleted_by?: string;
  taxpayer_id_number?: string;
  vehicle_license_plate?: string;
  about?: string;
  images: PersonImage[];
  files: PersonFiles[];
  videos: PersonVideos[];
  tags: Tag[];
  history: History[];
  requestedAtMs: number;
  areTheImageUrlsReady: boolean;
  reviewSettings: PersonReviewSettings[];
  sharing?: PersonSharingReview;
  super_profile?: SuperProfile;
}

export interface PersonPreviewPage {
  items: PersonPreview[];
  total: number;
}

export interface PersonFinderPeople {
  person: {
    id: string;
  };
  identification_score: number;
}

export interface PeopleByImageList {
  people: PersonFinderPeople[] | undefined;
  selectedPeople: PersonPreview[] | undefined;
  filters: {
    selectedScore: number;
    page: number;
    itemsPerPage: number;
  };
  file: {
    faces: Face[] | undefined;
    selectedFaceIndex: number;
    image: string;
    name: string;
  };
}

export interface PersonState {
  isLoading: boolean;
  data?: Person;
  tags: Tag[];
  error?: AxiosError;
}

export interface PersonFilterSettings {
  page: number;
  itemsPerPage: number;
  sortBy: string;
  sortOrder: string;
  search?: string;
  status?: string;
  hasFiles?: boolean;
  hasVideos?: boolean;
  tags?: string[];
  noTags?: boolean;
  createdAfter?: string;
  createdBefore?: string;
  createdDateOption?: string;
  sharingStatus?: string;
  unitId?: string;
  unitName?: string;
  noUnit?: boolean;
  hasFalseNegative?: boolean;
  workspaceId?: string;
  workspaceName?: string;
}

export interface PersonFalseNegativesReports {
  id: string;
  unit_id: string;
  about: string;
  created_at: string;
  status: string;
  video_url: string;
}

export interface PeopleState {
  peoplePreviewList: PersonPreviewPage;
  peopleByImageList: PeopleByImageList;
  person: PersonState;
  people: Person[];
  personFalseNegatives: PersonFalseNegativesReports[];
  personIteratorSettings?: PersonFilterSettings;
}
