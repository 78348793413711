/* eslint-disable @typescript-eslint/camelcase */
// Copyright (C) 2023 Deconve Technology. All rights reserved.

import moment from 'moment';

import {
  Notification,
  NotificationPreviewPage,
  NotificationPreview,
} from './types';

export function getDemoNotifications(): Promise<NotificationPreviewPage> {
  return new Promise((resolve) => {
    const notificationPreview00: NotificationPreview = {
      id: '00000000-0000-0000-0000-000000000000',
      video: { id: '00000000-0000-0000-0000-000000000002' },
      person: { id: '00000000-0000-0000-0000-000000000000' },
    };
    const notificationPreview01: NotificationPreview = {
      id: '00000000-0000-0000-0000-000000000001',
      video: { id: '00000000-0000-0000-0000-000000000002' },
      person: { id: '00000000-0000-0000-0000-000000000001' },
    };

    const notificationPreview02: NotificationPreview = {
      id: '00000000-0000-0000-0000-000000000002',
      video: { id: '00000000-0000-0000-0000-000000000003' },
      person: { id: '00000000-0000-0000-0000-000000000000' },
    };

    const notifications: NotificationPreview[] = [
      notificationPreview00, notificationPreview01, notificationPreview02,
    ];

    const data = { items: notifications, total: notifications.length };

    resolve(data);
  });
}

function getNotification00(preview: NotificationPreview): Notification {
  const createdAt = moment.utc().add(-25, 'seconds');

  const notification: Notification = {
    ...preview,
    created_at: createdAt.toISOString(),
    local_time_zone: -3,
    updated_at: moment(createdAt).add(15, 'seconds').toISOString(),
    expire_mode: undefined,
    expire_at: undefined,
    visible_time_ms: 5,
    best_face_index: 1,
    is_same_person: 'unreviewed',
    received_at: moment(createdAt).add(10, 'seconds').toISOString(),
    about: '',
    faces: [
      {
        created_at: createdAt.toISOString(),
        face_bounding_box: {
          top_left_x: 765,
          top_left_y: 250,
          width: 83,
          height: 90,
        },
        face_image: {
          original: 'faceid_notification_00_face_00.jpg',
          original_url: '/img/demo/faceid_notification_00/face_00.png',
          thumbnail: 'faceid_notification_00_face_00_thumbnail.jpg',
          thumbnail_url: '/img/demo/faceid_notification_00/face_00.png',
        },
        video_frame: {
          original: 'faceid_notification_00_frame_00.jpg',
          original_url: '/img/demo/faceid_notification_00/frame_00.jpg',
          thumbnail: 'faceid_notification_00_frame_00_thumbnail.jpg',
          thumbnail_url: '/img/demo/faceid_notification_00/frame_00.jpg',
          medium_quality: 'faceid_notification_00_frame_00_medium.jpg',
          medium_quality_url: '/img/demo/faceid_notification_00/frame_00.jpg',
          original_width: 1920,
          original_height: 1080,
        },
      },
      {
        created_at: moment(createdAt).add(100, 'milliseconds').toISOString(),
        face_bounding_box: {
          top_left_x: 773,
          top_left_y: 326,
          width: 100,
          height: 101,
        },
        face_image: {
          original: 'faceid_notification_00_face_01.jpg',
          original_url: '/img/demo/faceid_notification_00/face_01.png',
          thumbnail: 'faceid_notification_00_face_01_thumbnail.jpg',
          thumbnail_url: '/img/demo/faceid_notification_00/face_01.png',
        },
        video_frame: {
          original: 'faceid_notification_00_frame_01.jpg',
          original_url: '/img/demo/faceid_notification_00/frame_01.jpg',
          thumbnail: 'faceid_notification_00_frame_01_thumbnail.jpg',
          thumbnail_url: '/img/demo/faceid_notification_00/frame_01.jpg',
          medium_quality: 'faceid_notification_00_frame_01_medium.jpg',
          medium_quality_url: '/img/demo/faceid_notification_00/frame_01.jpg',
          original_width: 1920,
          original_height: 1080,
        },
      },
      {
        created_at: moment(createdAt).add(200, 'milliseconds').toISOString(),
        face_bounding_box: {
          top_left_x: 738,
          top_left_y: 399,
          width: 100,
          height: 113,
        },
        face_image: {
          original: 'faceid_notification_00_face_02.jpg',
          original_url: '/img/demo/faceid_notification_00/face_02.png',
          thumbnail: 'faceid_notification_00_face_02_thumbnail.jpg',
          thumbnail_url: '/img/demo/faceid_notification_00/face_02.png',
        },
        video_frame: {
          original: 'faceid_notification_00_frame_02.jpg',
          original_url: '/img/demo/faceid_notification_00/frame_02.jpg',
          thumbnail: 'faceid_notification_00_frame_02_thumbnail.jpg',
          thumbnail_url: '/img/demo/faceid_notification_00/frame_02.jpg',
          medium_quality: 'faceid_notification_00_frame_02_medium.jpg',
          medium_quality_url: '/img/demo/faceid_notification_00/frame_02.jpg',
          original_width: 1920,
          original_height: 1080,
        },
      },
    ],
    history: [
      {
        created_at: moment(createdAt).add(15, 'seconds').toISOString(),
        created_by: '00000000-0000-0000-0000-000000000000',
        action: 'com.deconve.faceid.notification:reviewed',
      },
    ],
    tags: [{
      id: '00000000-0000-0000-0000-000000000000',
    },
    {
      id: '00000000-0000-0000-0000-000000000001',
    }],
    requestedAtMs: Date.now(),
    is_from_a_shared_person: false,
    areTheImageUrlsReady: true,
  };

  return notification;
}

function getNotification01(preview: NotificationPreview): Notification {
  const createdAt = moment.utc().add(-2, 'days');

  const notification: Notification = {
    ...preview,
    created_at: createdAt.toISOString(),
    local_time_zone: -3,
    updated_at: moment(createdAt).add(15, 'seconds').toISOString(),
    expire_mode: undefined,
    expire_at: undefined,
    visible_time_ms: 5,
    best_face_index: 0,
    is_same_person: 'yes',
    received_at: moment(createdAt).add(10, 'seconds').toISOString(),
    about: '',
    faces: [
      {
        created_at: createdAt.toISOString(),
        face_bounding_box: {
          top_left_x: 834,
          top_left_y: 559,
          width: 83,
          height: 87,
        },
        face_image: {
          original: 'faceid_notification_01_face_00.jpg',
          original_url: '/img/demo/faceid_notification_01/face_00.png',
          thumbnail: 'faceid_notification_01_face_00_thumbnail.jpg',
          thumbnail_url: '/img/demo/faceid_notification_01/face_00.png',
        },
        video_frame: {
          original: 'faceid_notification_01_frame_00.jpg',
          original_url: '/img/demo/faceid_notification_01/frame_00.jpg',
          thumbnail: 'faceid_notification_01_frame_00_thumbnail.jpg',
          thumbnail_url: '/img/demo/faceid_notification_01/frame_00.jpg',
          medium_quality: 'faceid_notification_01_frame_00_medium.jpg',
          medium_quality_url: '/img/demo/faceid_notification_01/frame_00.jpg',
          original_width: 1920,
          original_height: 1080,
        },
      },
    ],
    history: [
      {
        created_at: moment(createdAt).add(10, 'seconds').toISOString(),
        created_by: '00000000-0000-0000-0000-000000000010',
        action: 'com.deconve.faceid.notification:created',
      },
      {
        created_at: moment(createdAt).add(15, 'seconds').toISOString(),
        created_by: '00000000-0000-0000-0000-000000000000',
        action: 'com.deconve.faceid.notification:reviewed',
      },
    ],
    tags: [],
    requestedAtMs: Date.now(),
    is_from_a_shared_person: true,
    areTheImageUrlsReady: true,
  };

  return notification;
}

function getNotification02(preview: NotificationPreview): Notification {
  const createdAt = moment.utc().add(-3, 'days');

  const notification: Notification = {
    ...preview,
    created_at: createdAt.toISOString(),
    local_time_zone: -3,
    updated_at: moment(createdAt).add(15, 'seconds').toISOString(),
    expire_mode: undefined,
    expire_at: undefined,
    visible_time_ms: 5,
    best_face_index: 0,
    is_same_person: 'yes',
    received_at: moment(createdAt).add(10, 'seconds').toISOString(),
    about: '',
    faces: [
      {
        created_at: createdAt.toISOString(),
        face_bounding_box: {
          top_left_x: 723,
          top_left_y: 223,
          width: 82,
          height: 103,
        },
        face_image: {
          original: 'faceid_notification_02_face_00.jpg',
          original_url: '/img/demo/faceid_notification_02/face_00.png',
          thumbnail: 'faceid_notification_02_face_00_thumbnail.jpg',
          thumbnail_url: '/img/demo/faceid_notification_02/face_00.png',
        },
        video_frame: {
          original: 'faceid_notification_02_frame_00.jpg',
          original_url: '/img/demo/faceid_notification_02/frame_00.jpg',
          thumbnail: 'faceid_notification_02_frame_00_thumbnail.jpg',
          thumbnail_url: '/img/demo/faceid_notification_02/frame_00.jpg',
          medium_quality: 'faceid_notification_02_frame_00_medium.jpg',
          medium_quality_url: '/img/demo/faceid_notification_02/frame_00.jpg',
          original_width: 1920,
          original_height: 1080,
        },
      },
    ],
    history: [
      {
        created_at: moment(createdAt).add(10, 'seconds').toISOString(),
        created_by: '00000000-0000-0000-0000-000000000010',
        action: 'com.deconve.faceid.notification:created',
      },
      {
        created_at: moment(createdAt).add(15, 'seconds').toISOString(),
        created_by: '00000000-0000-0000-0000-000000000000',
        action: 'com.deconve.faceid.notification:reviewed',
      },
    ],
    tags: [],
    requestedAtMs: Date.now(),
    is_from_a_shared_person: false,
    areTheImageUrlsReady: true,
  };

  return notification;
}

export function getDemoNotification(notificationId: string): Promise<Notification> {
  return new Promise((resolve) => {
    getDemoNotifications().then(({ items }) => {
      const nid01 = '00000000-0000-0000-0000-000000000001';
      const nid02 = '00000000-0000-0000-0000-000000000002';
      const preview = items.find(({ id }) => id === notificationId);
      const notificationPreviewId = preview?.id;

      let notification;

      switch (notificationPreviewId) {
        case nid01:
          notification = getNotification01(preview as NotificationPreview);
          break;

        case nid02:
          notification = getNotification02(preview as NotificationPreview);
          break;

        default:
          notification = getNotification00(preview as NotificationPreview);
          break;
      }

      resolve(notification);
    });
  });
}
