// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { WorkspaceState } from './types';
import { RootState } from '../types';

export const state: WorkspaceState = {
  isLoading: false,
  workspace: {
    id: '',
    name: '',
    host: '',
    // eslint-disable-next-line @typescript-eslint/camelcase
    brand_image: '',
    // eslint-disable-next-line @typescript-eslint/camelcase
    profile_image: '',
    // eslint-disable-next-line @typescript-eslint/camelcase
    created_at: '',
    ringtone: '',
    // eslint-disable-next-line @typescript-eslint/camelcase
    shared_with: [],
    // eslint-disable-next-line @typescript-eslint/camelcase
    shared_with_me: [],
  },
  workspaceUsers: [],
  workspaceList: [],
  invites: [],
  error: undefined,
};

const namespaced = true;

export const workspace: Module<WorkspaceState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
