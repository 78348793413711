// Copyright (C) 2020 Deconve Technology. All rights reserved.

import { ActionTree } from 'vuex';
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { dataUrlToBlob } from '../../../utils/data';
import {
  PeopleFilterOptions, PERSON_STATUS, personFileStatusToBoolean, personVideoStatusToBoolean,
} from '../../../utils/faceidFilters';
import { RootState } from '../../types';

import {
  PeopleState, Person, PersonFalseNegativesReports, PersonFilterSettings, PersonPreview, types,
} from './types';

import { isCachedDataValid, preparePersonToBeCached } from './utils';

import { getPeople, getPerson } from './demo';

interface FilterPeopleRequestParams {
  limit: number;
  skip: number;
  search?: string;
  sort_by: string;
  sort_order: string;
  people_status?: string;
  has_files?: boolean;
  has_videos?: boolean;
  created_after?: string;
  created_before?: string;
  sharing_status?: string;
  unit_id?: string;
  no_unit?: boolean;
  has_false_negative?: boolean;
  workspace_id?: string;
}

interface PersonReviewSettings {
  video: {
    id: string;
  };
  until: string;
  is_same_person: string;
  created_by: string;
  created_at: string;
}

export const actions: ActionTree<PeopleState, RootState> = {
  fetchPeople({ commit, state, rootGetters }): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!state.personIteratorSettings) {
        resolve(undefined);
        return;
      }

      const {
        page,
        itemsPerPage,
        sortBy,
        sortOrder,
        search,
        status,
        hasFiles,
        hasVideos,
        tags,
        noTags,
        createdAfter,
        createdBefore,
        sharingStatus,
        unitId,
        noUnit,
        hasFalseNegative,
        workspaceId,
      } = state.personIteratorSettings as PersonFilterSettings;

      const skip = itemsPerPage * (page - 1);

      let url = '/faceid/people/';

      // To be able to filter data without tags, we add them to the url
      if (noTags) {
        url += '?tag_ids';
      } else {
        (tags as string[]).forEach((tagId, index) => {
          if (!index) {
            url += `?tag_ids=${tagId}`;
          } else {
            url += `&tag_ids=${tagId}`;
          }
        });
      }

      const params: FilterPeopleRequestParams = {
        limit: itemsPerPage,
        skip,
        search,
        // eslint-disable-next-line @typescript-eslint/camelcase
        sort_by: sortBy,
        // eslint-disable-next-line @typescript-eslint/camelcase
        sort_order: sortOrder,
        // eslint-disable-next-line @typescript-eslint/camelcase
        people_status: status,
        // eslint-disable-next-line @typescript-eslint/camelcase
        has_files: hasFiles,
        // eslint-disable-next-line @typescript-eslint/camelcase
        has_videos: hasVideos,
        // eslint-disable-next-line @typescript-eslint/camelcase
        created_after: createdAfter,
        // eslint-disable-next-line @typescript-eslint/camelcase
        created_before: createdBefore,
      };

      if (sharingStatus) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        params.sharing_status = sharingStatus;
      }

      if (noUnit) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        params.no_unit = true;
      } else if (unitId) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        params.unit_id = unitId;
      }

      if (hasFalseNegative) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        params.has_false_negative = true;
      }

      const headers: AxiosRequestHeaders = { Authorization: rootGetters.authorizationToken };

      if (workspaceId) {
        headers.workspaceId = workspaceId;
      }

      const requestOptions: AxiosRequestConfig = {
        url,
        method: 'get',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        params,
        headers,
      };

      if (rootGetters.isDemoMode) {
        getPeople().then((data) => {
          commit(types.GET_FACEID_PEOPLE_SUCCESS, data);
          resolve();
        });
      } else {
        axios(requestOptions).then((response) => {
          const { data } = response;

          commit(types.GET_FACEID_PEOPLE_SUCCESS, data);
          resolve();
        }).catch((error) => reject(error));
      }
    });
  },
  fetchRelatedPeople({ rootGetters }, filterOptions): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!filterOptions) {
        resolve(undefined);
        return;
      }

      const {
        page,
        itemsPerPage,
        sortBy,
        sortOrder,
        search,
        workspaceId,
      } = filterOptions as PeopleFilterOptions;

      const skip = itemsPerPage * (page - 1);

      const params: FilterPeopleRequestParams = {
        limit: itemsPerPage,
        skip,
        search,
        // eslint-disable-next-line @typescript-eslint/camelcase
        sort_by: sortBy,
        // eslint-disable-next-line @typescript-eslint/camelcase
        sort_order: sortOrder,
        // eslint-disable-next-line @typescript-eslint/camelcase
        people_status: PERSON_STATUS.enabled,
      };

      const headers: AxiosRequestHeaders = { Authorization: rootGetters.authorizationToken };

      if (workspaceId) {
        headers.workspaceId = workspaceId;
      }

      const requestOptions: AxiosRequestConfig = {
        url: '/faceid/people/',
        method: 'get',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        params,
        headers,
      };

      axios(requestOptions).then((response) => {
        resolve(response.data);
      }).catch((error) => reject(error));
    });
  },
  addPerson({ commit, dispatch, rootGetters }, { payload, workspaceId }): Promise<string> {
    return new Promise((resolve, reject) => {
      commit(types.ADD_FACEID_PERSON_REQUEST);

      const headers: AxiosRequestHeaders = {
        'Content-Type': 'multipart/form-data',
        Authorization: rootGetters.authorizationToken,
      };

      if (workspaceId) {
        headers.workspaceId = workspaceId;
      }

      const requestOptions: AxiosRequestConfig = {
        method: 'post',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: '/faceid/people/',
        headers,
        data: payload,
      };

      axios(requestOptions).then((response) => {
        const { data } = response;

        const { person_id: personId } = data;

        dispatch('fetchPerson', personId).then(() => {
          resolve(personId);
        });
      }).catch((error) => {
        commit(types.ADD_FACEID_PERSON_FAILURE, error);
        reject(error);
      });
    });
  },
  editPerson({ commit, rootGetters }, { personId, payload }): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(types.EDIT_FACEID_PERSON_REQUEST);

      const requestOptions: AxiosRequestConfig = {
        method: 'put',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/faceid/people/${personId}/`,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: rootGetters.authorizationToken,
        },
        data: payload,
      };

      axios(requestOptions).then((response) => {
        let { data } = response as { data: Person };

        data = preparePersonToBeCached(data);

        commit(types.EDIT_FACEID_PERSON_SUCCESS, data);
        resolve();
      }).catch((error) => {
        commit(types.EDIT_FACEID_PERSON_FAILURE, error);
        reject(error);
      });
    });
  },
  fetchPersonHelper({ commit, rootGetters }, personId): Promise<Person> {
    return new Promise((resolve, reject) => {
      if (!personId) {
        reject();
        return;
      }

      commit(types.GET_FACEID_PERSON_REQUEST);

      const requestOptions: AxiosRequestConfig = {
        method: 'get',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/faceid/people/${personId}/`,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      if (rootGetters.isDemoMode) {
        getPerson(personId).then((person) => {
          const data = preparePersonToBeCached(person);

          commit(types.GET_FACEID_PERSON_SUCCESS, data);
          resolve(data);
        });
      } else {
        axios(requestOptions).then((response) => {
          let { data } = response as { data: Person };

          data = preparePersonToBeCached(data);

          commit(types.GET_FACEID_PERSON_SUCCESS, data);
          resolve(data);
        }).catch((error) => {
          commit(types.GET_FACEID_PERSON_FAILURE);
          reject(error);
        });
      }
    });
  },
  fetchPerson({ dispatch, getters }, personId): Promise<Person> {
    return new Promise((resolve) => {
      const cachedPerson = getters.getPerson(personId);

      if (isCachedDataValid(cachedPerson)) {
        resolve(cachedPerson);
      } else {
        resolve(dispatch('fetchPersonHelper', personId));
      }
    });
  },
  fetchPersonIgnoringCachedData({ dispatch }, personId): Promise<Person> {
    return dispatch('fetchPersonHelper', personId);
  },

  fetchPeopleByImage({ commit, dispatch, rootGetters }, image): Promise<void> {
    // Remove prefix added by base64 encoder. This prefix is not accepted by back-end
    const imageWithoutBase64Prefix = image.split('64,')[1];

    return new Promise((resolve, reject) => {
      const requestOptions: AxiosRequestConfig = {
        method: 'post',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: '/faceid/actions/identify/faces/',
        data: { image: imageWithoutBase64Prefix },
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      axios(requestOptions).then((response) => {
        commit(types.GET_FACEID_PERSON_FINDER_PEOPLE_SUCCESS, response.data);
        dispatch('filterPersonFinderPeopleByIdentificationScore');
        resolve();
      }).catch((error) => {
        commit(types.GET_FACEID_PERSON_FINDER_PEOPLE_SUCCESS, []);
        reject(error);
      });
    });
  },
  deleteDetectedFaces({ commit }) {
    commit(types.RESET_FACEID_PERSON_FINDER_IMAGE_FACES);
  },
  detectFacesInTheChosenImage({ commit, state, rootGetters }): Promise<void> {
    return new Promise((resolve, reject) => {
      const { image, name } = state.peopleByImageList.file;

      if (!image) return;

      dataUrlToBlob(image).then((img) => {
        const formData = new FormData();

        formData.append('image', img as Blob, name);

        const requestOptions: AxiosRequestConfig = {
          method: 'post',
          url: 'faceid/actions/detect/faces/',
          baseURL: process.env.VUE_APP_DECONVE_API_URL,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: rootGetters.authorizationToken,
          },
          data: formData,
        };

        axios(requestOptions).then((response) => {
          const { faces } = response.data;

          if (faces) {
            commit(types.GET_FACEID_PERSON_FINDER_IMAGE_FACES, faces);
          }

          resolve();
        }).catch(() => {
          reject();
        });
      });
    });
  },
  filterPersonFinderPeopleByIdentificationScore({ commit, state }): void {
    const { people } = state.peopleByImageList;

    const selectedPeople: PersonPreview[] = [];

    if (people) {
      const { selectedScore } = state.peopleByImageList.filters;

      people.forEach(({ identification_score: identificationScore, person }) => {
        if (identificationScore >= selectedScore) {
          selectedPeople.push(person);
        }
      });
    }

    commit(types.SET_FACEID_PERSON_FINDER_SELECTED_PEOPLE, selectedPeople);
  },
  selectPersonFinderFaceIndex({ commit }, index) {
    commit(types.SET_FACEID_PERSON_FINDER_IMAGE_FACE_INDEX, index);
  },
  setPersonFinderImage({ commit }, file) {
    commit(types.ADD_FACEID_PERSON_FINDER_IMAGE, file);
  },
  setPersonFinderScoreThreshold({ commit, dispatch }, value) {
    commit(types.SET_FACEID_PERSON_FINDER_SCORE_THRESHOLD, value);
    dispatch('filterPersonFinderPeopleByIdentificationScore');
  },
  removePersonFinderByImage({ commit }) {
    commit(types.REMOVE_FACEID_PERSON_FINDER_IMAGE);
  },
  setPersonFinderPagination({ commit }, data) {
    commit(types.SET_FACEID_PERSON_FINDER_PAGINATION, data);
  },

  addPersonTag({ commit, rootGetters }, { personId, tagId }) {
    commit(types.ADD_FACEID_PERSON_TAG_REQUEST);

    const requestOptions: AxiosRequestConfig = {
      method: 'post',
      baseURL: process.env.VUE_APP_DECONVE_API_URL,
      url: `/faceid/people/${personId}/tags/`,
      // eslint-disable-next-line @typescript-eslint/camelcase
      data: { tag_id: tagId },
      headers: {
        Authorization: rootGetters.authorizationToken,
      },
    };

    axios(requestOptions).then(() => {
      commit(types.ADD_FACEID_PERSON_TAG_SUCCESS, tagId);
    }).catch((error) => {
      commit(types.ADD_FACEID_PERSON_TAG_FAILURE, error);
    });
  },
  deletePersonTag({ commit, rootGetters }, { personId, tagId }) {
    commit(types.DELETE_FACEID_PERSON_TAG_REQUEST);

    const requestOptions: AxiosRequestConfig = {
      method: 'delete',
      baseURL: process.env.VUE_APP_DECONVE_API_URL,
      url: `/faceid/people/${personId}/tags/${tagId}/`,
      headers: {
        Authorization: rootGetters.authorizationToken,
      },
    };

    axios(requestOptions).then(() => {
      commit(types.DELETE_FACEID_PERSON_TAG_SUCCESS, tagId);
    }).catch((error) => {
      commit(types.DELETE_FACEID_PERSON_TAG_FAILURE, error);
    });
  },
  resetPersonTags({ commit }) {
    commit(types.RESET_FACEID_PERSON_TAG);
  },
  deletePerson({ commit, rootGetters }, personId): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(types.DELETE_FACEID_PERSON_REQUEST);

      const requestOptions: AxiosRequestConfig = {
        method: 'delete',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/faceid/people/${personId}/`,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      axios(requestOptions).then(() => {
        commit(types.DELETE_FACEID_PERSON_SUCCESS, personId);
        resolve();
      }).catch((error) => {
        commit(types.DELETE_FACEID_PERSON_FAILURE);
        reject(error);
      });
    });
  },
  patchPersonProfile({ commit, rootGetters }, { personId, payload }): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(types.EDIT_FACEID_PERSON_REQUEST);

      const requestOptions: AxiosRequestConfig = {
        method: 'patch',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/faceid/people/${personId}/`,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: rootGetters.authorizationToken,
        },
        data: payload,
      };

      axios(requestOptions).then((response) => {
        let { data } = response as { data: Person };

        data = preparePersonToBeCached(data);

        commit(types.EDIT_FACEID_PERSON_SUCCESS, data);
        resolve();
      }).catch((error) => {
        commit(types.EDIT_FACEID_PERSON_FAILURE, error);
        reject(error);
      });
    });
  },
  addPersonReviewSettings({ dispatch }, { personId, reviewSettings }): Promise<void> {
    return new Promise((resolve, reject) => {
      // fetchPerson will get the person from cache, if she is available
      dispatch('fetchPerson', personId).then((person) => {
        const { review_settings: currentReviewSettings } = person;
        const { video_id: videoId } = reviewSettings;

        const payload = new FormData();

        currentReviewSettings.forEach((review: PersonReviewSettings) => {
          const { video, ...rest } = review;

          if (video.id !== videoId) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            const settings = { ...rest, video_id: video.id };

            payload.append('review_settings', JSON.stringify(settings));
          }
        });

        // image is sent in formdata. To be able to use the same endpoint, we send json as string
        payload.append('review_settings', JSON.stringify(reviewSettings));

        dispatch('patchPersonProfile', { personId, payload }).then(() => {
          resolve();
        }).catch((error) => reject(error));
      });
    });
  },
  addPersonFalseNegatives({ rootGetters },
    { personId, formData }): Promise<void> {
    return new Promise((resolve, reject) => {
      const requestOptions: AxiosRequestConfig = {
        method: 'post',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/faceid/people/${personId}/false-negatives/`,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: rootGetters.authorizationToken,
        },
        data: formData,
      };

      axios(requestOptions).then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  },
  fetchPersonFalseNegatives({ commit, rootGetters },
    personId): Promise<PersonFalseNegativesReports> {
    return new Promise((resolve, reject) => {
      const requestOptions: AxiosRequestConfig = {
        method: 'get',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/faceid/people/${personId}/false-negatives`,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      axios(requestOptions).then((response) => {
        const { data } = response as { data: PersonFalseNegativesReports };

        commit('setPersonFalseNegatives', data);
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  deletePersonFalseNegative({ commit, rootGetters }, { personId, falseNegativeId }): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(types.DELETE_FACEID_PERSON_REQUEST);

      const requestOptions: AxiosRequestConfig = {
        method: 'delete',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/faceid/people/${personId}/false-negatives/${falseNegativeId}`,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      axios(requestOptions).then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  },
  deletePersonReviewSettings({ dispatch }, { personId, videoIdToDelete }): Promise<void> {
    return new Promise((resolve, reject) => {
      // fetchPerson will get the person from cache, if she is available
      dispatch('fetchPerson', personId).then((person) => {
        const { review_settings: reviewSettings } = person;

        const payload = new FormData();
        let isToClearReviewSettings = true;

        reviewSettings.forEach((review: PersonReviewSettings) => {
          const { video, ...rest } = review;

          if (video.id !== videoIdToDelete) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            const settings = { ...rest, video_id: video.id };

            payload.append('review_settings', JSON.stringify(settings));
            isToClearReviewSettings = false;
          }
        });

        if (isToClearReviewSettings) {
          payload.append('review_settings', '');
        }

        dispatch('patchPersonProfile', { personId, payload }).then(() => {
          resolve();
        }).catch((error) => reject(error));
      });
    });
  },
  reviewPersonSharing({ commit, rootGetters }, { personId, sharingOptions }): Promise<void> {
    return new Promise((resolve, reject) => {
      const payload = {
        ...sharingOptions,
        about: sharingOptions.status === 'approved' ? '' : sharingOptions.about,
      };

      commit(types.EDIT_FACEID_PERSON_REQUEST);

      const requestOptions: AxiosRequestConfig = {
        method: 'patch',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/faceid/people/${personId}/actions/sharing/`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: rootGetters.authorizationToken,
        },
        data: payload,
      };

      axios(requestOptions).then((response) => {
        let { data } = response as { data: Person };

        data = preparePersonToBeCached(data);

        commit(types.EDIT_FACEID_PERSON_SUCCESS, data);
        resolve();
      }).catch((error) => {
        commit(types.EDIT_FACEID_PERSON_FAILURE, error);
        reject(error);
      });
    });
  },
  changePersonActivation({ commit, rootGetters },
    { personId, disableOptionSelected = null }): Promise<void> {
    return new Promise((resolve, reject) => {
      const isToDisable = disableOptionSelected !== null;
      let payload = {};

      if (isToDisable) {
        payload = {
          enabled: !isToDisable,
          // eslint-disable-next-line @typescript-eslint/camelcase
          disable_reason_code: disableOptionSelected,
        };
      } else {
        payload = {
          enabled: !isToDisable,
        };
      }

      commit(types.EDIT_FACEID_PERSON_REQUEST);

      const requestOptions: AxiosRequestConfig = {
        method: 'patch',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/faceid/people/${personId}/actions/activation/`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: rootGetters.authorizationToken,
        },
        data: payload,
      };

      axios(requestOptions).then((response) => {
        let { data } = response as { data: Person };

        data = preparePersonToBeCached(data);

        commit(types.EDIT_FACEID_PERSON_SUCCESS, data);
        resolve();
      }).catch((error) => {
        commit(types.EDIT_FACEID_PERSON_FAILURE, error);
        reject(error);
      });
    });
  },
  setPersonIteratorSettings({ commit }, filterOptions: PeopleFilterOptions) {
    const {
      createdAfter, createdBefore, createdDateOption, sortOrder, sortBy, tags, noTags, status,
      fileStatus, videoStatus, sharingStatus, unitId, unitName, noUnit, hasFalseNegative,
      search, page, itemsPerPage, workspaceId, workspaceName,
    } = filterOptions;

    const data = {
      createdAfter,
      createdBefore,
      createdDateOption,
      status,
      hasFiles: personFileStatusToBoolean(fileStatus),
      hasVideos: personVideoStatusToBoolean(videoStatus),
      sortOrder,
      sortBy,
      sharingStatus,
      tags,
      noTags,
      page,
      search,
      itemsPerPage,
      unitId,
      unitName,
      noUnit,
      hasFalseNegative,
      workspaceId,
      workspaceName,
    };

    commit('setPersonIteratorSettings', data);
  },
  resetPersonError({ commit }) {
    commit('resetError');
  },
  resetPerson({ commit }) {
    commit('resetPerson');
  },
};

export default actions;
