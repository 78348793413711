/* eslint-disable @typescript-eslint/camelcase */
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import i18n from '@/plugins/i18n';
import moment from 'moment';
import {
  SuperProfile, SuperProfilePreview, SuperProfilesPreviewPage, SuperProfileSharingReview,
} from './types';

function createSharingStatus(): SuperProfileSharingReview {
  const aboutReview = i18n.t('deconve.onboarding.faceid.superProfile.aboutReview') as string;
  const createdAt = moment.utc().add(-5, 'days').toISOString();
  const updatedAt = moment.utc().add(-3, 'days').toISOString();

  const currentReview = {
    status: 'waiting',
    created_at: createdAt,
    updated_at: updatedAt,
    about: '',
    tags: [],
  };
  const lastReview = {
    status: 'rejected',
    created_at: createdAt,
    updated_at: updatedAt,
    about: aboutReview,
    tags: [],
  };

  const sharingStatus = { current_review: currentReview, last_review: lastReview };

  return sharingStatus;
}

export function getSuperProfiles(): Promise <SuperProfilesPreviewPage> {
  return new Promise((resolve) => {
    const superProfile01: SuperProfilePreview = {
      id: '00000000-0000-0000-0000-000000000000',
      name: i18n.t('deconve.onboarding.faceid.superProfile.name') as string,
    };

    const superProfiles = [superProfile01];

    const data = { items: superProfiles, total: superProfiles.length };

    resolve(data);
  });
}

export function getSuperProfile00(): SuperProfile {
  const createdAt = moment.utc().add(-5, 'days').toISOString();
  const sharingStatus = createSharingStatus();

  const superProfile: SuperProfile = {
    id: '00000000-0000-0000-0000-000000000000',
    name: i18n.t('deconve.onboarding.faceid.superProfile.name') as string,
    created_at: createdAt,
    updated_at: createdAt,
    about: i18n.t('deconve.onboarding.faceid.superProfile.about') as string,
    status: 'waiting',
    tags: [
      {
        id: '00000000-0000-0000-0000-000000000001',
      },
    ],
    people: [{ id: '00000000-0000-0000-0000-000000000000' }],
    similar_super_profiles: [],
    profile_image: 'profile_image.jpg',
    profile_image_url: '/img/demo/faceid_person_00/image_00.jpg',
    images: [
      {
        id: '00000000-0000-0000-0000-000000000009',
        original: 'entrada_principal.jpg',
        original_url: '/img/demo/faceid_person_00/image_00.jpg',
        thumbnail: 'entrada_principal_thumbnail.jpg',
        thumbnail_url: '/img/demo/faceid_person_00/image_00.jpg',
        medium_quality: 'entrada_principal_medium.jpg',
        medium_quality_url: '/img/demo/faceid_person_00/image_00.jpg',
        original_width: 1920,
        original_height: 1080,
        faces: [
          {
            bounding_box: {
              top_left_x: 1029,
              top_left_y: 232,
              width: 94,
              height: 110,
            },
            detection_score: 0.9972921013832092,
            image_quality_score: 0.8176610469818115,
            angles: {
              yaw: 8.264114570552453,
              pitch: 14.958168852459607,
              roll: 6.490493464061614,
            },
            landmarks: [
              1058.7137451171875,
              279.49005126953125,
              1092.6807861328125,
              275.9355163574219,
              1077.6385498046875,
              298.83282470703125,
              1066.81982421875,
              314.372802734375,
              1093.0809326171875,
              311.48797607421875,
            ],
          },
        ],
      },
    ],
    history: [
      {
        created_at: createdAt,
        created_by: '00000000-0000-0000-0000-000000000000',
        action: 'com.deconve.faceid.person:created',
      },
    ],
    sharing_status: sharingStatus,
  };

  return superProfile;
}

export function getSuperProfileById(superProfileId: string): Promise <SuperProfile> {
  return new Promise((resolve) => {
    if (superProfileId === '00000000-0000-0000-0000-000000000000') {
      const superProfile = getSuperProfile00();

      resolve(superProfile);
    } else {
      const sharingStatus = createSharingStatus();

      const superProfile: SuperProfile = {
        id: superProfileId,
        name: superProfileId,
        tags: [],
        status: 'waiting',
        people: [],
        similar_super_profiles: [],
        images: [],
        history: [],
        sharing_status: sharingStatus,
      };

      resolve(superProfile);
    }
  });
}
